export const environment = {
  production: true,
  mode: 'prod',
  APP_REGISTRATION: {
    CLIENT_ID: 'f69bb773-2f59-4b48-a5dc-821a0d40e9a3',
  },
  BACKEND_URL: 'https://stellargpt-ghq.ab-inbev.com/stellar',
  GATEWAY_URL: 'https://stellargpt-ghq.ab-inbev.com/genai',
  APIM_SUBSCRIPTION_KEY: '',
  APPLICATION_ID: '6e3247ab-45f4-499c-858a-c4a3df3e716c',
  PERSONAL_RAG_CONNECTOR_ID: '97cf0286-648a-4a7f-827a-b9a4be17bfd4',
  APP_INSIGHTS: {
    INSTRUMENTATION_KEY: '',
    CONNECTION_STRING: '',
  },
  APPLICATION_LABEL: 'Stellar',
  LAYOUT: {
    PERSONAL_RAG: false,
    CONTEXT_SELECTOR: false,
  },
};
